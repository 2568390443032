<template>
  <div>
    <b-button v-if="permissions.includes('adicionar')" @click="$router.push('/configuracoes/perfil/novo')"
      >Adicionar</b-button
    >
    <Card class="mt-2">
      <b-table class="mt-2" small striped hover :items="profiles" :fields="fields">
        <template #cell(id)="data">
          <b-dropdown
            class="p-0 m-0 d-flex"
            size="md"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template #button-content>
              <fa-icon size="1x" icon="bars" />
            </template>
            <b-dropdown-item v-if="permissions.includes('editar')" @click="$router.push(`/configuracoes/perfil/${data.item.id}/editar`)"
              >Editar</b-dropdown-item
            >
          </b-dropdown>
        </template>
        <template #cell(profile_type_id)="data">
          {{profileTypesById[data.item.profile_type_id].type}}
        </template>
        <template #cell(active)="data">
          <b-badge v-if="data.item.active == '1'" pill variant="success">Sim</b-badge>
          <b-badge v-if="data.item.active == '0'" pill variant="warning">Não</b-badge>
        </template>
      </b-table>
    </Card>    
  </div>
</template>

<script>
export default {
  data() {
    return {
      fields: [
        {
          key: "id",
          label: "#",
          thStyle: {
            width: "5%",
            textAlign: "center",
          },
          thClass: "text-muted small",
          tdClass: "align-middle",
        },
        {
          key: "name",
          label: "Perfil",
          thStyle: {
            width: "40%",
          },
          thClass: "text-muted small",
          tdClass: "align-middle",
        },
        {
          key: "profile_type_id",
          label: "Tipo de Perfil",
          thStyle: {
            width: "30%",
          },
          thClass: "text-muted small",
          tdClass: "align-middle",
        },
        {
          key: "active",
          label: "Ativo",
          thStyle: {
            width: "5%",
          },
          thClass: "text-muted small",
          tdClass: "align-middle",
        },
      ],
    };
  },
  computed: {
    profiles() {
      return this.$store.state.configurations.profiles;
    },
    profileTypes() {
      return this.$store.state.configurations.profileTypes;
    },
    profileTypesById(){
      return _.keyBy(this.profileTypes, 'id')
    },
    permissions() {
      let permissions = [];
      try {
        const response =
          this.$store.state.auth.burgerPermissions.configuracoes.perfil.habilitar;
        if (response) {
          permissions = response;
        }
      } catch (e) {}

      return permissions;
    },
  },
  mounted() {
    const unityId = this.$store.state.auth.currentUser.unity_id;
    this.$store.dispatch("configurations/profiles_init", unityId);
  },
};
</script>

<style>
</style>